.info__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.0rem 2.8rem 0 2rem;
    width: 33rem;
}

.info__title {
    font-size: 3.2rem;
    /* color: #4bbb17; */
    color: #f5f5f5;
    font-weight: 400;
    margin: 3rem 0 0 0;
}

.info__text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.4rem;
    text-align: center;
}

.info__image {
    overflow: hidden;
}

.info__image--img {
    width: 100%;
    height: 100%;

}

.info__position-one {
    order: 1;
}

.info__position-two{
    order: 2;
}