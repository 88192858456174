.footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    bottom: .8rem;
    left: 0;
    right: 0;
    height: 5rem;
    justify-content: space-around;
    align-items: center;
}

.footer-copyright {
    margin-right: .3rem;
}