.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.header__top {
    font-size: 4rem;
    /* color: #4bbb17; */
    color: #f5f5f5;
    font-weight: 400;
    font-family: 'Mooli', sans-serif;
    margin-bottom: .4rem;
}

.header__bottom {
    font-size: 1.7rem;
    font-family: 'Mooli', sans-serif;
    color: #e5e5e5;
}