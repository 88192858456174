
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  color: #e5e5e5;
  background-color: #000000;
  font-family: 'Mooli', sans-serif;
}

::selection {
    background: #4bbb17;
}

.page__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.page {
    margin: 3rem 2rem;
}

.divider {
    width: 100%;
    border-bottom: 2px solid #e5e5e5;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.color-white {
    color: #ffffff;
}

.cursor-pointer {
    cursor: pointer;
}

.no-font-family {
    font-family: 'Courier New', Courier, monospace;
}